import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import slugify from "slugify";

function banner(props) {
  const { thumbnail, name, seoMetaData } = props.data;
  return (
    <div className="col-xl-2 col-lg-3 col-sm-4 col-6">
      <div className="category-m py-3">
        <div className="category-wrapper text-center">
          <div className="card">
            <div className="card-img">
              <Link
                to={`/collections/${slugify(
                  seoMetaData?.slug || name.toLowerCase()
                )}/`}
                className="cat-block"
              >
                <GatsbyImage
                  image={thumbnail?.childImageSharp?.gatsbyImageData}
                  // className="img-fluid bg-img"
                  alt=""
                />

                <h3 className="cat-block-title">{name}</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(banner);