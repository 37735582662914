import React, { useState } from "react";

const Newletter = () => {
  const [status, setStatus] = useState("init");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    setStatus("submitting");
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        firstName: name,
        email: email,
        newsLetterRequest: {
          isNewsLetter: true,
        },
      }),
    })
      .then((res) => {
        setStatus("success");
        setEmail("");
        setName("");
      })
      .catch((err) => {
        setStatus("failed");
        console.log(err);
      });
  };

  return (
    <div
      className="cta bg-image bg-dark pt-4 pb-5 mb-0"
      style={{ backgroundImage: `url(/assets/images/home/bg-5.jpg)` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-8 col-lg-6">
            <div className="cta-heading text-center">
              <h3 className="cta-title text-white">
                Subscribe to our Newsletter
              </h3>
              <p className="cta-desc text-white">
                Subscribe to our newsletter for special
                <span className="font-weight-normal">
                  discounts, promotions, and new inventory
                </span>{" "}
                updates that you won't want to miss.
              </p>
            </div>

            <form
              className="subscribe-form"
              name="subscribe"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div className="input-group ">
                {/* style={ isIEBrowser() ? { border: 'none' } : {} } */}
                <input
                  type="email"
                  className="form-control form-control-white"
                  placeholder="Enter your Email Address"
                  aria-label="Email Adress"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-group ">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  aria-label="name"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    disabled={status === "submitting" || status === "success"}
                    type="submit"
                  >
                    <span style={{ color: "#000" }}>Subscribe</span>
                    <i
                      style={{ color: "#000" }}
                      className="icon-long-arrow-right"
                    ></i>
                  </button>
                </div>
              </div>
            </form>
            {status === "success" && (
              <div className="text-success text-center">
                <i className="fa fa-check-circle" aria-hidden="true"></i> You've
                successfully subscribed our Newsletter.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newletter;
