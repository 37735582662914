import React, { useState, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";

import ProductNine from "../features/product/product-nine";

import {
  addToCart,
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
} from "../../actions";

const sliderProps = {
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Products = (props) => {
  const [products, setProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);

  useEffect(() => {
    const { products, recentproducts } = props;
    const getItems = async () => {
      if (products.length > 0) {
        setProducts(products);
        setRecentProducts(recentproducts);
      }
      const result = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/api/v1/gatsby/items?isFeatured=true`,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
          },
        }
      )
        .then((res) => {
          if (res && res.status === 200) return res.json();
        })
        .catch((err) => {
          console.log(err.json());
        });

      if (result) {
        const newProducts = products.map((product) => {
          const foundItem = result.find((obj) => obj._id === product._id);
          const { price, oldPrice, quantity } = foundItem;
          return { ...product, price, oldPrice, quantity };
        });

        setProducts(newProducts);
        setRecentProducts(recentproducts);
      }
    };
    getItems();
  }, []);

  const count = products.length;
  const recentCount = recentProducts.length;
  return (
    <div className="bg-light container-fluid pt-5 pb-6">
      <Tabs selectedTabClassName="show" defaultIndex={0}>
        <div className="container trendy-collection">
          <div className="heading heading-center mb-3">
            <h2 className="title-lg">Featured Products</h2>

            <TabList
              className="nav nav-pills justify-content-center"
              role="tablist"
            >
              <Tab className="nav-item">
                <span className="nav-link">Featured</span>
              </Tab>
              <Tab className="nav-item">
                <span className="nav-link">Recently Added</span>
              </Tab>
            </TabList>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div className="tab-content  just-action-icons-sm">
              <TabPanel>
                <div className="container">
                  <p className="text-center mb-3" style={{ color: "black" }}>
                    When it's gone, it's gone for Good!
                  </p>

                  <Slider {...sliderProps}>
                    {products.map((item, index) => (
                      <ProductNine
                        product={item}
                        key={`recent-${index}`}
                        onAddToCart={addToCart}
                        onToggleWishlist={toggleWishlist}
                        onAddToCompare={addToCompare}
                        showQuickView={showQuickViewModal}
                      />
                    ))}
                  </Slider>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container">
                  <p
                    className="text-center text-black mb-3"
                    style={{ color: "black" }}
                  >
                    New Deals; Gotta catch ’em all!
                  </p>

                  <div className="row">
                    <div
                      className={
                        recentCount >= 4
                          ? "col"
                          : recentCount >= 3
                          ? "col-7"
                          : recentCount >= 2
                          ? " col-lg-4 col-xs-12 col-sm-12  "
                          : "col-xl-3 col-lg-12 col-md-12 col-xs-12  col-sm-12 "
                      }
                    >
                      <Slider
                        {...sliderProps}
                        slidesToShow={recentCount > 6 ? 6 : recentCount}
                        className=""
                      >
                        {recentProducts.map((item, index) => (
                          <ProductNine
                            product={item}
                            key={`featured-${index}`}
                            onAddToCart={addToCart}
                            onToggleWishlist={toggleWishlist}
                            onAddToCompare={addToCompare}
                            showQuickView={showQuickViewModal}
                          />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

const Index = () => (
  <StaticQuery
    query={graphql`
      {
        recentproducts: allProducts(
          sort: { fields: createdAt, order: DESC }
          limit: 6
          filter: { title: { ne: null } }
        ) {
          nodes {
            _id
            title
            description
            quantity
            price
            oldPrice
            rating
            isDefaultShippingEnabled
            isDeliverable

            isPickup
            isShippable
            seoMetaData {
              slug
            }
            createdAt
            image: thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  height: 300
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }

            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  height: 100
                  quality: 100
                  layout: FIXED
                )
              }
            }
            condition
          }
        }
        products: allProducts(filter: { isFeatured: { eq: true } }) {
          nodes {
            _id
            title
            description
            quantity
            price
            oldPrice
            rating
            isDefaultShippingEnabled
            isDeliverable

            isPickup
            isShippable
            seoMetaData {
              slug
            }

            image: thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 160
                  height: 175
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  height: 100
                  quality: 100
                  layout: FIXED
                )
              }
            }
            condition
          }
        }
      }
    `}
    render={({ products, recentproducts }) => (
      <Products
        recentproducts={recentproducts.nodes}
        products={products.nodes}
      />
    )}
  />
);

export default Index;
