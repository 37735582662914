import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import "./home-slider.scss";

const containsYoutubeLink = (string) => {
  const youtubeLinkRegex =
    /https:\/\/www\.youtube\.com\/embed\/[\w-]+\?[\w=&]+/;
  return youtubeLinkRegex.test(string);
};

const extractYoutubeLink = (string) => {
  const youtubeLinkRegex =
    /https:\/\/www\.youtube\.com\/embed\/[\w-]+\?[\w=&]+/;
  const youtubeLink = string.match(youtubeLinkRegex);
  return youtubeLink ? youtubeLink[0] : null;
};

const settings = {
  responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
      },
    },
  ],
};
const HomeSlider = () => (
  <StaticQuery
    query={graphql`
      {
        allSliderImages {
          edges {
            node {
              url {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
                url
              }
            }
          }
        }
      }
    `}
    render={({ allSliderImages }) => (
      <section className="p-0">
        <Slider {...settings} className="slide-1 home-slider">
          {allSliderImages.edges.map(({ node }, key) => {
            return containsYoutubeLink(node.url.url) ? (
              <div key={key} className="youtube-video">
                <iframe
                  width="100%"
                  src={extractYoutubeLink(node.url.url)}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  className="responsive-iframe"
                ></iframe>
              </div>
            ) : (
              <div key={key}>
                {/* intro-slide */}
                <div
                  className="home text-center intro-slide "
                  style={{ position: `relative` }}
                >
                  <GatsbyImage
                    image={node.url.childImageSharp.gatsbyImageData}
                    loading={key === 0 ? "eager" : "lazy"}
                    alt={`image-slider${key}`}
                    style={{ width: "100%", height: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                  />
                  <div
                    className="container btn-container"
                    style={{ maxWidth: "100vw" }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        {/* btn btn-dark btn-outline-darker */}
                        <Link
                          tabIndex={-1}
                          to={`/allcollection/`}
                          className="btn btn-primary"
                        >
                          <span style={{ color: "black" }}>Shop</span>
                          <i
                            style={{ color: "black" }}
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
    )}
  />
);

export default HomeSlider;
