import React, { useState, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";

import ProductNine from "../features/product/product-sm";

import {
  addToCart,
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
} from "../../actions";

const sliderProps = {
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Products = (props) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getItems = async () => {
      const { products } = props;

      if (products.length > 0) setProducts(products);

      const result = JSON.parse(localStorage.getItem("recentItems")) || [];

      if (result) {
        const newProducts = products.filter((product) => {
          return result.includes(product._id);
        });

        setProducts(newProducts);
      }
    };
    getItems();
  }, []);

  // const count = products.length;
  return (
    <div className="bg-light container-fluid">
      <div className="container trendy-collection">
        <div className="heading heading-center mb-3">
          {/* <h2 className="title-lg">Trendy Products</h2> */}
        </div>
      </div>
      <div className="row text-center" style={{ textAlign: "center" }}>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          <div className="tab-content  just-action-icons-sm center-block">
            <div className="container">
              <h2 className="title text-center mb-3">
                Recently Viewed Products
              </h2>

              <Slider {...sliderProps}>
                {products.map((item, index) => (
                  <ProductNine
                    product={item}
                    key={index}
                    onAddToCart={addToCart}
                    onToggleWishlist={toggleWishlist}
                    onAddToCompare={addToCompare}
                    showQuickView={showQuickViewModal}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Index = () => (
  <StaticQuery
    query={graphql`
      {
        products: allProducts(filter: { title: { ne: null } }) {
          nodes {
            _id
            title
            description
            quantity
            price
            brand
            oldPrice
            rating
            isDefaultShippingEnabled
            isDeliverable
            isPickup
            isShippable
            seoMetaData {
              slug
            }
            image: thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 160
                  height: 175
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  height: 100
                  quality: 100
                  layout: FIXED
                )
              }
            }
            condition
          }
        }
      }
    `}
    render={({ products }) => <Products products={products.nodes} />}
  />
);

export default Index;
