import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import slugify from "slugify";

import { GatsbyImage } from "gatsby-plugin-image";

import { addToCart } from "../../../reducers/cart";
import { showQuickViewModal } from "../../../actions";

function ProductNine(props) {
  const { product, addToCart, showQuickView, type = 1 } = props;


  const [stock] = useState(product.quantity);
  const [quantity] = useState(1);
  const addToCartHandler = () => {
    if (0 !== stock) addToCart({ ...product, quantity, stock });
  };

  const quickViewHandler = () => {
    showQuickView(product);
  };

  return product ? (
    <div className="product product-2 mr-2">
      <figure aria-labelledby={`product-${product._id}`} className="product-media">
        {product.discount ? (
          <span className="product-label label-circle label-sale">Sale</span>
        ) : (
          ""
        )}
        {0 === product.stock ? (
          <span className="product-label label-circle label-out">Out</span>
        ) : (
          ""
        )}

        <Link  to={`/products/${
            product.seoMetaData?.slug || slugify(product.title.toLowerCase())
          }/`}
          title={product.title}
          className="text-center"
          >
          {/* <div style={{display:'flex',width:'100%'}}> */}
          <GatsbyImage
            image={product.image.childImageSharp.gatsbyImageData}
            alt="product"
            // id={`pr-${product.title.substring(0,3)}`}
            // style={{ display: "flex", maxWidth: "100%" }}
            className="img-fluid" />
          {/* </div> */}
        </Link>

        <div className="product-action product-action-dark">
          <button className="btn-product btn-cart" onClick={addToCartHandler}>
            {type === 1 ? <span>add to cart</span> : ""}
          </button>

          <button
            className="btn-product btn-quickview"
            title="Quick view"
            onClick={quickViewHandler}
          >
            {type === 1 ? <span>quick view</span> : ""}
          </button>
        </div>
      </figure>

      <div className="product-body">
        <div className="product-cat">
          {/* { product.category.map( ( cat, index ) => (
                            <span key={ `cat_${index}` } className="mr-0">
                                <Link to="#">{ cat }</Link>
                                { index < product.category.length - 1 ? ', ' : '' }
                            </span>
                        ) ) } */}
        </div>

        <h3 className="product-title" id={`product-${product._id}`}>
          <Link  to={`/products/${
            product.seoMetaData?.slug || slugify(product.title.toLowerCase())
          }/`}
          title={`${product.title}`}
          >
           <span>{product.title.substring(0, 26) + "..."}</span> 
          </Link>
        </h3>
    {/* {100*(product.oldPrice??0-product.price)/product.oldPrice}{"OFF"} */}
        {0 < product.oldPrice && product.oldPrice>product.price ? (
          <div className="product-price">
            <span className="new-price">
              $
              {product.price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="old-price">
              $
              {product.oldPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}


            </span>
 
          </div>
        ) : (
          <div className="product-price">
            $
            {product.price.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        )}
           {
  Number(product.oldPrice)- Number(product.price)>0 && <label className="product-label2 label-rounded">Save{" "}<b>{(100*(Number(product.oldPrice)- Number(product.price))/product.oldPrice).toFixed(1)}%</b></label>
}
        <div className="ratings-container">
          <div className="ratings">
            
            <div
              className="ratings-val"
              style={{ width: product.rating * 20 + "%" }}
            ></div>
          </div>
          <span className="ratings-text">({ product.rating?.toFixed(1)??0 })</span>
        </div>

        {product.variants ? (
          product.variants[0].model ? (
            <div className="product-nav product-nav-thumbs">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={`vari_${i}`}
                  className={0 === i ? "active" : ""}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/" + vari.model}
                    alt="product desc"
                  />
                </Link>
              ))}
            </div>
          ) : (
            <div className="product-nav product-nav-dots">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={`vari_${i}`}
                  className={0 === i ? "active" : ""}
                  style={{ background: vari.color }}
                ></Link>
              ))}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    showQuickView: (item) => dispatch(showQuickViewModal(item)),
  };
}

export default connect(null, mapDispatchToProps)(ProductNine);
